import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import Button from "@material-ui/core/Button";
// import PersonIcon from '@material-ui/icons/Person';


// import FurtherReading from "../components/furtherReading"

const OutsourcingInfographics = () => (
  <Layout>
    <SEO
      title="Outsourcing Infographics"
      description="6 pro tips for designing your own infographics. Inform leads about your business quickly and expand on your mission statement"
    />
    <h1>Outsourcing Infographics</h1>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/vjHkwKG.png"
        alt="Outsourcing Infographics"
      />
    </div>

    <h2>Everything You Need to Know About Outsourcing Infographics</h2>

    <p>If you’re reading this article, you’ve decided that you want to to get your infographic made and uploaded to your website ASAP. The only problem is where exactly should you start? There are a few options for getting an infographic made; make it yourself, hire a freelancer or hire an agency. Let’s go over them quickly.</p>
    
    <h2>Make it Yourself</h2>
    <p>If you are a competent graphic designer and already have the software, you probably have everything you need to make an infographic right now. This is by far the cheapest cash option but also the most time intensive. You can design it exactly how you want it.</p>
    <p>If you are good with graphic design but don’t have much experience with infographics specifically, there is inspiration all over the internet One of my favourite place to go is Pinterest. Not just for infographics but for anything design related.</p>
    <p>The downside to making it yourself, is that you could end up wasting time. You might have the vision but if your skills don’t meet your taste, you could end up not using the final result. I mentioned time already but you could end up wasting a lot of it. This is time that you could have spent better in places that you are really skilled at like actually running your business.</p>
    
    <h2>Hire a Freelancer</h2>
    <p>This is the middle option, somewhere between doing it yourself and closer to hiring an agency. The advantages are that as mentioned, you get to spend your time doing the other things in your business that you are good at. You also get to work with someone who does this for a living and has vast experience in infographic design.</p>
    <p>Your taste and their might not but I am fairly confident that an experienced freelancer can leave their own taste aside to give a client that can communicate well, exactly what they want. A freelancer will have a design portfolio, often online, that you can browse through. You can do this before you even contact them to see if you want to work for them or not.</p>
    <p>The pricing of a freelancer will likely be reflected by their experience, skill and confidence in their own abilities. Freelancers are easy to find but good freelancer are harder to find. You can take a risk and go with a skilled designer with little experience in the hopes of getting a good price. Or you could go the opposite direction and spend good money for a skilled designer with lots of experience.</p>
    <p>A disadvantage is that they might not communicate well and take a long time to deliver. A lot of the negatives that you would expect to find with any freelancer can be true for graphic designers, that’s why you have to read reviews, testimonials, check their previous work and do as much research as you can. Without wasting time and procrastinating.</p>

    <h2>Hire an Agency</h2>
    <p>About as time intensive as hiring a freelancer but much more expensive. Agencies have higher overheads and have to charge much more which means they often work with big clients who can afford it.</p>
    <p>The funny hiring one or the other is that the quality of work will not vary too much between a freelancer and an agency. The differences will be that at an agency, you might have multiple points of contact where a freelancer will be one person. The agency might put more emphasis on getting contract written and hosting formal meetings.  It could end up taking longer to get started which is bad if you want your infographic quickly.</p>
    <p>The upside is that because an agency has multiple people, they can bounce ideas around a lot more and when there is so much design experience in one place, this is usually a good thing.</p>
    <p>The other benefit to having more people is that everyone usually has different specialities. One person might be a colour phycology expert, another a data science expert and another person might be a copy expert (they specialise in writing great content that sells). A freelancer has to be great at not just one of these things but all of them.</p>

    <h2>What Should You Do?</h2>
    <p>You have to weigh up your priorities and decide which option is best for you. Each option can provide amazing results and just because you chose one this time, doesn’t mean you can’t change your mind next time.</p>
    <p>If you are happy with the results, you can make this a regular part of your business content strategy and make infographics every time you have some new data and information you want to promote.</p>

    <h2>Where Do We Fit In?</h2>
    <p>We know that hiring online can be stressful if you haven’t done it before. There are a few things that can go wrong and you could end up wasting time. You won’t know if you have hired a good designer until after you have worked with them.</p>
    <p>This leads to one of the main hurdles of hiring someone online being the question of; can I trust this person and will they deliver what i want?</p>
    <p>That’s why we made this section of the website. We regularly search for the best infographic designers and give them a platform so you can learn more about them and how they work. These are designers that love doing what they do but don’t have the time to promote their work as much as they could.</p>
    <p>They have worked with some amazing clients, some designers are freelance and others with their own design agencies. It’s the perfect opportunity to work with some of the greatest minds in design to help grow your business.</p>

    {/* <div style={{ paddingBottom: "30px", textAlign:"center" }}>
    <Link to="/infographics/designer/"
      style={{ textDecoration: "none" }}
    >
        <Button
              variant="contained"
              // color="primary"
              disableElevation            
              style={{
                width: "35ch",
                backgroundColor: "#D8564A",
                color: "white",
              }}
            >
              view designer profile
              <PersonIcon style={{ paddingLeft: "8px", paddingBottom: "2px" }} />
            </Button>
          </Link>
          </div> */}

          {/* <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link> */}

    <Link to="/infographics/" style={{ textDecoration: "none" }}>
      Go back to the infographics homepage
    </Link>
  </Layout>
)

export default OutsourcingInfographics
